import { Component, OnInit } from '@angular/core';
import * as Sentry from "@sentry/angular";
import { LanguageService } from './core/services/language.service';
import { AuthService } from './auth/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'BurningLeads - Your Growth Partner';

  constructor(private authService: AuthService,
    private languageService: LanguageService) {
    authService.handleAuthentication();
    this.setDefaultDocumentAttributes();
  }

  ngOnInit(): void {
    this.languageService.init();
    this.authService.renewToken();
    this.setUserContextToSentry();

    this.authService.userLoggedIn$.subscribe(() => {
      this.setUserContextToSentry();
    });
  }

  private setDefaultDocumentAttributes(): void {
    const attributes = [
      ['layout', 'vertical'],
      ['data-layout', 'vertical'],
      ['data-topbar', 'light'],
      ['data-sidebar', 'dark'],
      ['data-sidebar-size', 'lg'],
      ['data-layout-style', 'default'],
      ['data-layout-mode', 'dark'],
      ['data-layout-width', 'fluid'],
      ['data-layout-position', 'fixed'],
      ['data-preloader', 'disable'],
      ['data-sidebar-image', 'none']
    ];

    attributes.forEach(([attribute, value]) => {
      document.documentElement.setAttribute(attribute, value);
    });
  }

  private setUserContextToSentry() {
    if (this.authService.isAuthenticated) {
      Sentry.setUser({
        id: this.authService.currentUserID,
        email: this.authService.userProfile?.email,
        username: this.authService.userProfile?.username,
      });
      Sentry.setTag('Channel', 'BurningLeads');
    }
  }

}
