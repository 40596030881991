import * as Sentry from "@sentry/angular";
import { ErrorHandler, NgZone, Inject, Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";

@Injectable()
export class AppErrorHandler implements ErrorHandler {
    constructor(@Inject(NgZone) private ngZone: NgZone) { }

    handleError(error: any): void {
        if (environment.production) {
            Sentry.captureException(error.originalError || error);
        }

        this.ngZone.run(() => {
            console.log('BurningLeads ERROR:');
            console.log(error);
        });
    }
}
