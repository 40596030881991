import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AuthService } from '../../auth/services/auth.service';

export const ErrorInterceptor: HttpInterceptorFn = (request, next) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return next(request).pipe(
    catchError((err: any) => {
      if (err.status === 401) {
        if (authService.isAuthenticated) {
          // If 401 response returned from api Redirect to Unauthorized page
          router.navigate(['/unauthorized']);
        }
      }
      return throwError(() => err);
    })
  );
};
