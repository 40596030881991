import { MenuItem } from './menu-item';

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: 'sideBar.search',
    link: '',
    icon: 'bx bx-search-alt-2',
    subItems: [
      {
        id: 2,
        parentId: 1,
        label: 'sideBar.leadGeneration',
        link: '',
      },
      {
        id: 3,
        parentId: 1,
        label: 'sideBar.dataEnrichment',
        link: 'data-enrichment',
      },
      {
        id: 4,
        parentId: 1,
        label: 'sideBar.dataExtraction',
        link: 'data-extraction',
      },
    ],
  },
  {
    id: 5,
    label: 'sideBar.queries',
    link: 'queries',
    icon: 'bx bx-layer',
  },
  {
    id: 6,
    label: 'sideBar.dashboard',
    link: 'dashboard',
    icon: 'bx bx-pie-chart-alt-2',
  },


  {
    id: 7,
    label: 'UI Samples',
    link: '',
    icon: 'bx bx-palette',
    subItems: [
      {
        id: 8,
        parentId: 7,
        label: 'Typography',
        link: 'ui-samples/typography',
      },
      {
        id: 9,
        parentId: 7,
        label: 'Colors',
        link: 'ui-samples/colors',
      },
      {
        id: 10,
        parentId: 7,
        label: 'Buttons',
        link: 'ui-samples/buttons',
      },
      {
        id: 11,
        parentId: 7,
        label: 'Forms',
        link: 'ui-samples/forms',
      },
      {
        id: 12,
        parentId: 7,
        label: 'Alerts',
        link: 'ui-samples/alerts',
      },
      {
        id: 13,
        parentId: 7,
        label: 'Dialogs',
        link: 'ui-samples/dialogs',
      },
      {
        id: 14,
        parentId: 7,
        label: 'Cards',
        link: 'ui-samples/cards',
      },
      {
        id: 15,
        parentId: 7,
        label: 'progress',
        link: 'ui-samples/progress',
      }
    ]
  },
  
  {
    id: 16,
    label: 'sideBar.settings',
    link: 'settings',
    icon: 'bx bx-cog',
    isSeperated: true
  },
  {
    id: 17,
    label: 'sideBar.help',
    link: 'help',
    icon: 'bx bx-help-circle'
  }
];
