import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '../../auth/services/auth.service';

export const AuthInterceptor: HttpInterceptorFn = (request, next) => {
    const authService = inject(AuthService);

    if (request.headers.get('Authorization') === 'No-Auth') {
        const modifiedRequest = request.clone({
            headers: request.headers.delete('Authorization')
        });
        return next(modifiedRequest);
    }

    if (authService.isAuthenticated) {
        const modifiedRequest = request.clone({
            setHeaders: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': request.headers.has('Authorization') ?
                    request.headers.get('Authorization')! : `Bearer ${authService.accessToken}`
            }
        });
        return next(modifiedRequest);
    }

    return next(request);
};
