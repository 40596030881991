<div class="app-menu navbar-menu">
  <!-- LOGO -->
  <div class="navbar-brand-box mb-3">
    <a routerLink="/" class="logo logo-light">
      <span class="logo-sm">
        <svg xmlns="http://www.w3.org/2000/svg" height="35" viewBox="0 0 1080 1564" fill="none">
          <path
            d="M1079.9 996.472C1056.53 535.135 690.622 188.184 302.672 0C308.207 7.89224 523.45 313.435 430.178 500.183C428.641 503.463 427.001 506.743 425.053 509.921C419.416 520.068 412.651 529.805 404.862 539.03C376.367 572.853 329.732 587.51 288.118 572.341C283.608 570.701 279.303 568.754 275.203 566.601C271.923 564.961 269.054 563.014 266.286 560.964C250.912 549.894 243.122 535.135 247.837 519.555C247.837 519.555 168.197 591.815 99.2167 709.071C78.5124 744.228 58.628 783.484 42.3311 825.917C42.1261 826.43 42.0236 826.737 41.8186 827.25C17.5269 890.798 0.922469 961.52 0 1037.06C0.409986 1157.9 40.8961 1269.42 108.954 1358.9C109.569 1359.82 110.286 1360.64 110.901 1361.36C250.297 1528.53 468.819 1603.25 680.577 1543.7C712.044 1534.78 742.793 1523.1 772.312 1508.65C953.628 1419.78 1070.88 1234.57 1079.39 1033.68C1080.01 1021.48 1080.11 1008.98 1079.9 996.472ZM795.169 1188.76C735.925 1263.27 659.976 1316.36 563.116 1332.15C481.324 1345.47 363.863 1327.43 306.567 1261.84C249.067 1196.14 238.715 1099.48 266.901 1018.71C300.213 923.187 369.603 843.752 438.173 771.389C659.566 538.21 604.832 376.675 599.4 362.325C632.814 439.608 669.098 510.023 670.738 596.12C672.378 678.322 652.801 760.73 615.39 833.81C569.369 923.699 472.919 987.042 447.295 1086.87C426.796 1166.72 483.169 1209.05 558.299 1190.91C636.811 1172.05 707.124 1122.95 754.17 1057.56C789.941 1007.95 832.477 913.245 808.288 849.594C814.54 863.944 819.358 878.908 823.663 893.77C850.517 985.915 858.306 1109.32 795.169 1188.76Z"
            fill="url(#paint0_linear_18_1131)" />
          <defs>
            <linearGradient id="paint0_linear_18_1131" x1="0" y1="781.946" x2="1080.01" y2="781.946"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#FF3A2D" />
              <stop offset="1" stop-color="#FFA91A" />
            </linearGradient>
          </defs>
        </svg>
      </span>
      <span class="logo-lg">
        <svg xmlns="http://www.w3.org/2000/svg" height="35" viewBox="0 0 1080 249" fill="none">
          <path
            d="M171.652 158.39C167.937 85.0603 109.775 29.912 48.1101 0C48.9899 1.25448 83.203 49.8208 68.3773 79.5047C68.133 80.0261 67.8723 80.5474 67.5627 81.0525C66.6667 82.6654 65.5914 84.2131 64.3532 85.6794C59.8241 91.0557 52.4112 93.3855 45.7967 90.9743C45.0798 90.7136 44.3956 90.404 43.7439 90.0619C43.2226 89.8012 42.7664 89.4917 42.3265 89.1659C39.8827 87.4063 38.6445 85.0603 39.3939 82.5839C39.3939 82.5839 26.7351 94.0697 15.7706 112.708C12.4796 118.296 9.319 124.536 6.72858 131.281C6.69599 131.362 6.6797 131.411 6.64712 131.492C2.78592 141.593 0.146628 152.835 0 164.842C0.0651678 184.05 6.50049 201.776 17.3183 215.999C17.4161 216.145 17.5301 216.276 17.6279 216.39C39.785 242.962 74.5194 254.839 108.179 245.373C113.18 243.956 118.068 242.098 122.76 239.801C151.58 225.676 170.218 196.237 171.571 164.304C171.668 162.366 171.685 160.378 171.652 158.39ZM126.393 188.954C116.976 200.798 104.904 209.238 89.508 211.747C76.507 213.864 57.8364 210.997 48.7292 200.57C39.5895 190.127 37.944 174.764 42.4243 161.926C47.7191 146.742 58.7488 134.115 69.6481 122.613C104.839 85.549 96.1388 59.8729 95.2754 57.5921C100.587 69.8762 106.354 81.0688 106.615 94.754C106.875 107.82 103.763 120.919 97.8169 132.535C90.5018 146.823 75.1711 156.892 71.0981 172.76C67.8397 185.451 76.8003 192.18 88.7423 189.296C101.222 186.298 112.398 178.495 119.876 168.1C125.562 160.215 132.323 145.161 128.478 135.044C129.472 137.325 130.238 139.703 130.922 142.066C135.191 156.712 136.429 176.328 126.393 188.954Z"
            fill="url(#paint0_linear_18_1115)" />
          <path
            d="M260.15 139.72C283.903 133.529 282.812 93.5971 261.013 86.2494C250.766 82.6652 217.383 84.5551 205.05 84.0989V201.629C221.945 200.814 263.522 205.653 272.939 191.935C287.471 175.643 282.942 145.161 260.15 139.72ZM224.079 99.1689C237.634 99.2178 258.863 96.3504 257.657 115.689C258.895 134.278 238.824 133.268 224.079 132.828V99.1689ZM224.079 186.038V147.898C240.518 147.181 261.974 146.741 261.274 167.009C262.577 187.716 239.752 186.592 224.079 186.038Z"
            fill="#F5F5F5" />
          <path
            d="M342.457 114.662C341.822 128.82 344.526 171.879 339.264 182.339C336.934 189.866 323.217 192.065 319.649 185.385C315.82 177.077 318.541 125.138 317.742 114.662H299.838C300.538 127.989 297.948 180.302 301.988 191.202C308.7 209.775 337.228 207.119 343.842 188.53V201.629H360.362V114.662H342.457Z"
            fill="#F5F5F5" />
          <path
            d="M397.393 134.116V114.664H381.639V201.63H399.544C401.857 182.585 389.231 125.482 422.45 131.803V113.116C410.573 111.487 402.867 118.509 397.393 134.116Z"
            fill="#F5F5F5" />
          <path
            d="M481.965 116.813C470.284 108.325 450.978 112.528 446.204 128.087V114.662H429.684V201.629H447.589C448.697 189.035 444.477 141.512 451.336 132.258C454.823 126.246 467.237 124.829 470.316 131.15C474.291 139.54 471.473 191.088 472.288 201.629H490.209C487.814 184.604 496.986 126.621 481.965 116.813Z"
            fill="#F5F5F5" />
          <path d="M528.69 114.662H510.785V201.629H528.69V114.662Z" fill="#F5F5F5" />
          <path
            d="M602.15 116.813C590.485 108.325 571.163 112.528 566.405 128.087V114.662H549.869V201.629H567.774C568.882 189.035 564.662 141.512 571.537 132.258C575.008 126.246 587.422 124.829 590.501 131.15C594.477 139.54 591.674 191.088 592.489 201.629H610.394C608.015 184.604 617.171 126.621 602.15 116.813Z"
            fill="#F5F5F5" />
          <path
            d="M695.683 192.88C687.83 183.496 679.619 184.913 665.754 183.463C655.767 182.942 649.609 182.258 647.295 181.394C641.219 178.657 643.385 170.023 650.342 168.133C679.065 177.989 704.268 147.426 685.044 123.02C686.168 114.679 691.593 112.235 698.566 113.978V100.798C686.282 98.8105 678.299 102.932 676.865 116.389C644.412 99.1363 611.258 139.296 641.137 163.392C626.263 169.68 623.151 187.357 637.781 195.08C622.352 199.169 621.082 219.566 633.512 227.745C644.412 236.282 678.397 235.435 689.345 226.898C700.244 220.674 702.704 202.558 695.683 192.88ZM673.069 141.447C674.878 164.06 646.758 164.011 648.452 141.609C646.725 119.078 674.845 118.051 673.069 141.447ZM646.22 218.247C638.579 213.636 640.306 201.482 648.876 198.012C654.415 198.468 674.796 198.191 678.967 201.14C695.862 219.534 658.912 225.774 646.22 218.247Z"
            fill="#F5F5F5" />
          <path d="M729.211 185.175V84.0996H709.759V201.63H770.805V185.175H729.211Z" fill="#F5F5F5" />
          <path
            d="M776.833 159.434C772.369 206.485 831.769 223.38 843.548 176.133L827.11 173.982C824.927 190.519 809.466 195.374 799.935 184.295C796.742 180.369 795.161 172.549 795.161 160.818H844.33C849.397 97.3776 774.747 96.058 776.833 159.434ZM825.986 148.681H795.601C795.226 117.808 827.745 117.954 825.986 148.681Z"
            fill="#F5F5F5" />
          <path
            d="M918.377 128.446C909.253 103.079 860.378 109.156 858.097 137.129L875.399 139.28C876.164 125.92 892.619 120.984 899.478 130.417C901.824 133.838 902.492 141.919 902.264 147.98C886.803 147.247 871.081 150.163 864.662 156.158C853.258 164.761 851.336 187.015 861.567 197.019C874.095 209.156 896.366 204.708 903.649 187.162C903.649 193.646 904.024 198.469 904.757 201.629H921.733C918.035 186.217 922.548 145.145 918.377 128.446ZM873.688 177.256C872.841 163.082 886.689 157.201 902.264 159.27C906.207 189.264 875.594 203.34 873.688 177.256Z"
            fill="#F5F5F5" />
          <path
            d="M985.533 84.0996V124.732C978.169 108.586 953.014 109.955 944.135 125.172C938.726 133.155 936.021 144.282 936.021 158.57C936.021 171.897 938.465 182.65 943.353 190.861C952.379 207.69 980.124 207.69 986.901 188.45V201.63H1003.44V84.0996H985.533ZM980.775 184.148C977.599 187.765 974.079 189.574 970.202 189.574C950.701 193.712 949.218 122.842 970.023 126.279C981.183 127.453 985.712 137.016 985.533 150.457C985.484 159.613 986.657 178.886 980.775 184.148Z"
            fill="#F5F5F5" />
          <path
            d="M1073.95 160.345C1069.55 154.17 1045.98 148.207 1040.03 144.02C1026.75 126.034 1058.5 116.683 1062.76 138.774L1076.78 134.978C1072.19 111.616 1043.25 105.93 1027.79 120.381C1009.89 138.074 1023.69 162.186 1047.08 165.803C1086.87 177.419 1038.81 209.661 1032.01 175.627L1016.78 178.462C1017.77 204.545 1054.51 211.029 1071.36 195.813C1081.41 187.422 1083.24 169.11 1073.95 160.345Z"
            fill="#F5F5F5" />
          <path
            d="M508.146 30.2705C508.423 30.6615 518.931 45.5849 514.369 54.7084C514.304 54.8714 514.223 55.0343 514.125 55.1972C513.848 55.686 513.522 56.1584 513.131 56.6146C511.746 58.2601 509.465 58.9769 507.429 58.2438C507.217 58.1623 507.005 58.0646 506.793 57.9505C506.647 57.8691 506.5 57.7876 506.37 57.6899C505.604 57.1359 505.229 56.4191 505.457 55.6534C505.457 55.6534 501.58 59.1887 498.208 64.9235C497.197 66.6341 496.22 68.5566 495.422 70.6257C495.422 70.6583 495.405 70.6746 495.389 70.6908C494.216 73.8026 493.401 77.2565 493.353 80.9385C493.369 86.8525 495.356 92.294 498.68 96.6765C498.713 96.7091 498.745 96.758 498.778 96.7906C505.588 104.953 516.259 108.602 526.604 105.702C528.152 105.262 529.651 104.692 531.085 103.992C539.948 99.6416 545.682 90.5996 546.09 80.7756C546.122 80.1891 546.122 79.57 546.122 78.9672C544.982 56.4191 527.093 39.4755 508.146 30.2705ZM532.209 88.3513C529.309 92.0007 525.611 94.5911 520.87 95.3569C516.878 96.0085 511.127 95.1288 508.325 91.9356C505.523 88.7097 505.018 84.0014 506.386 80.0587C508.015 75.3829 511.404 71.5054 514.76 67.9701C525.578 56.582 522.922 48.713 522.645 47.9799C524.275 51.7596 526.05 55.1972 526.132 59.4005C526.213 63.4083 525.252 67.4487 523.427 71.0167C521.179 75.3992 516.455 78.4947 515.216 83.3823C514.206 87.2761 516.96 89.3451 520.642 88.4654C524.47 87.5367 527.908 85.1418 530.205 81.9486C531.899 79.6025 533.903 75.1548 532.942 72.0594C533.219 72.6785 533.414 73.3139 533.594 73.9492C534.913 78.4458 535.288 84.4738 532.209 88.3513Z"
            fill="url(#paint1_linear_18_1115)" />
          <defs>
            <linearGradient id="paint0_linear_18_1115" x1="0" y1="124.291" x2="171.668" y2="124.291"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#FF3A2D" />
              <stop offset="1" stop-color="#FFA91A" />
            </linearGradient>
            <linearGradient id="paint1_linear_18_1115" x1="493.353" y1="68.4751" x2="546.122" y2="68.4751"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#FF3A2D" />
              <stop offset="1" stop-color="#FFA91A" />
            </linearGradient>
          </defs>
        </svg>
      </span>
    </a>
  </div>
  <div class="container-fluid">
    <ngx-simplebar class="sidebar-menu-scroll">
      <ul class="metismenu list-unstyled navbar-nav" id="navbar-nav">
        <ng-container *ngFor="let item of menuItems">
          <li class="menu-title" *ngIf="item.isTitle">
            <span data-key="t-menu">
              {{ item.label | translate }}
            </span>
          </li>
          <li class="nav-item" [ngClass]="{ 'mt-auto': item.isSeperated }" *ngIf="!item.isTitle && !item.isLayout">
            <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent nav-link menu-link"
              [ngClass]="{ 'has-arrow': !item.badge, 'has-dropdown': item.badge }" data-bs-toggle="collapse"
              aria-expanded="false" (click)="toggleItem($event)">
              <i class="{{ item.icon }} icon nav-icon"></i>
              <span>{{ item.label | translate }}</span>
              <span class="badge badge-pill {{ item.badge.variant }}" *ngIf="item.badge">
                {{ item.badge.text | translate }}
              </span>
            </a>
            <a [routerLink]="item.link" *ngIf="!hasItems(item)" class="side-nav-link-ref nav-link menu-link"
              (click)="toggleParentItem($event)">
              <i class="{{ item.icon }} icon nav-icon" *ngIf="item.icon"></i>
              <span>{{ item.label | translate }}</span>
              <span class="badge {{ item.badge.variant }} float-end" *ngIf="item.badge">
                {{ item.badge.text | translate }}
              </span>
            </a>
            <div class="collapse menu-dropdown" *ngIf="hasItems(item)">
              <ul *ngIf="hasItems(item)" class="nav nav-sm flex-column" aria-expanded="false">
                <li *ngFor="let subitem of item.subItems; let last = last" class="nav-item"
                  [ngClass]="{ 'last-item': last }">
                  <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem)" class="nav-link"
                    [attr.data-parent]="subitem.parentId" (click)="updateActive($event)">
                    {{ subitem.label | translate }}
                    <span class="badge badge-pill {{ subitem.badge.variant }}" *ngIf="subitem.badge">
                      {{ subitem.badge.text | translate }}
                    </span>
                  </a>
                  <a *ngIf="hasItems(subitem)" class="nav-link" href="javascript:void(0);"
                    [attr.data-parent]="subitem.parentId" data-bs-toggle="collapse" aria-expanded="false"
                    (click)="toggleSubItem($event)">
                    {{ subitem.label | translate }}
                    <span class="badge badge-pill {{ subitem.badge.variant }}" *ngIf="subitem.badge">
                      {{ subitem.badge.text | translate }}
                    </span>
                  </a>
                  <div class="collapse menu-dropdown sub-menu" *ngIf="hasItems(subitem)">
                    <ul *ngIf="hasItems(subitem)" class="nav nav-sm flex-column" aria-expanded="false">
                      <li *ngFor="let subSubitem of subitem.subItems" class="nav-item">
                        <a [attr.data-parent]="subSubitem.parentId" *ngIf="!hasItems(subSubitem)"
                          [routerLink]="subSubitem.link" class="nav-link" (click)="updateActive($event)">
                          {{ subSubitem.label | translate }}
                        </a>
                        <a *ngIf="hasItems(subSubitem)" class="nav-link" href="javascript:void(0);"
                          [attr.data-parent]="subSubitem.parentId" data-bs-toggle="collapse" aria-expanded="false"
                          (click)="toggleExtraSubItem($event)">
                          {{ subSubitem.label | translate }}
                          <span class="badge badge-pill {{
                                subSubitem.badge.variant
                              }}" *ngIf="subSubitem.badge">
                            {{ subSubitem.badge.text | translate }}
                          </span>
                        </a>
                        <div class="collapse menu-dropdown extra-sub-menu" *ngIf="hasItems(subSubitem)">
                          <ul *ngIf="hasItems(subSubitem)" class="nav nav-sm flex-column" aria-expanded="false">
                            <li *ngFor="let subSubitem1 of subSubitem.subItems" class="nav-item">
                              <a [attr.data-parent]="subSubitem1.parentId" [routerLink]="subSubitem1.link"
                                class="nav-link" (click)="updateActive($event)">
                                {{ subSubitem1.label | translate }}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ng-container>
      </ul>
    </ngx-simplebar>
  </div>
</div>
<div class="vertical-overlay" (click)="SidebarHide()"></div>