import { Component, EventEmitter, Output } from '@angular/core';
import { AuthService } from '../../../auth/services/auth.service';
import { FirestoreService } from '../../../core/services/firestore.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent {

  collapsed = false;
  numberOfCredits = 25;

  @Output() menuButtonClicked = new EventEmitter();

  constructor(public authService: AuthService,
    private firestoreService: FirestoreService) { }

  toggleMenu(event: any) {
    event.preventDefault();
    this.collapsed = !this.collapsed;
    this.menuButtonClicked.emit();
  }

  windowScroll() {
    if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
      document.getElementById('page-topbar')?.classList.add('topbar-shadow')
    } else {
      document.getElementById('page-topbar')?.classList.remove('topbar-shadow')
    }
  }

  logout() {
    this.firestoreService.signOut();
    this.authService.logout();
  }

}
