<div id="layout-wrapper">
    <!-- Top Bar -->
    <app-topbar (menuButtonClicked)="onToggleMenu()"></app-topbar>
    <!-- Side Bar -->
    <app-sidebar></app-sidebar>
    <div class="main-content">
        <div class="page-content">
            <div class="container-fluid">
                <!-- Main Content -->
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>