<header id="page-topbar" data-scroll-header (window:scroll)="windowScroll()">
    <div class="layout-width">
        <div class="navbar-header">
            <button type="button" class="btn fs-24 text-white-75" (click)="toggleMenu($event)">
                <i class="bx" [ngClass]="{'bx-left-indent': !collapsed, 'bx-right-indent': collapsed}"></i>
            </button>

            <div class="d-flex align-items-center">
                <div class="dropdown ms-sm-3 header-item topbar-user" ngbDropdown *ngIf="authService.userProfile">
                    <button type="button" class="btn shadow-none" id="page-header-user-dropdown"
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                        <span class="d-flex align-items-center">
                            <img class="rounded-circle header-profile-user" [src]="authService.userProfile.picture"
                                alt="Header Avatar" />
                            <span class="text-start ms-xl-2">
                                <span class="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                                    {{
                                    this.authService.userProfile.name ?
                                    this.authService.userProfile.name :
                                    this.authService.userProfile.nickname
                                    }}
                                </span>
                                <div class="d-none d-xl-block ms-1 fs-12">
                                    <div class="d-flex align-items-center gap-1">
                                        <i class="bx bx-wallet"></i>
                                        <span>{{ 'topBar.credits' | translate }}:</span>
                                        <span>{{ numberOfCredits }}</span>
                                    </div>
                                </div>
                            </span>
                        </span>
                    </button>
                    <div class="dropdown-menu dropdown-menu-end w-100" ngbDropdownMenu>
                        <a class="dropdown-item d-flex align-items-center" routerLink="/account/profile">
                            <i class="bx bx-user-circle fs-17 me-2"></i>
                            <span>{{ 'topBar.profile' | translate }}</span>
                        </a>
                        <a class="dropdown-item d-flex align-items-center" routerLink="/plans-and-billing">
                            <i class="bx bx-wallet-alt fs-17 me-2"></i>
                            <span>{{ 'topBar.plansAndBilling' | translate }}</span>
                        </a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item d-flex align-items-center" href="javascript: void(0);" (click)="logout()">
                            <i class="bx bx-log-out-circle fs-17 me-2"></i>
                            <span>{{ 'topBar.logout' | translate }}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>