import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../../auth/services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.headers.get('Authorization') === 'No-Auth') {
            request = request.clone({
                headers: request.headers.delete('Authorization')
            });
            return next.handle(request.clone());
        }

        if (this.authService.isAuthenticated) {
            request = request.clone({
                setHeaders: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': request.headers.has('Authorization') ?
                        request.headers.get('Authorization')! : `Bearer ${this.authService.accessToken}`
                }
            });
        }

        return next.handle(request);
    }
}
