import { Component, EventEmitter, Output } from '@angular/core';
import { AuthService } from '../../../auth/services/auth.service';
import { FirestoreService } from '../../../core/services/firestore.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrl: './topbar.component.scss',
  standalone: false
})
export class TopbarComponent {

  collapsed = false;
  numberOfCredits = 25;
  currentModule: string = '';
  subPage: string = '';

  @Output() menuButtonClicked = new EventEmitter();

  constructor(public authService: AuthService,
    private firestoreService: FirestoreService,
    private router: Router) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      const urlParts = this.router.url.split('/').filter(part => part);
      if (urlParts.length > 0) {
        this.currentModule = this.formatBreadcrumb(urlParts[0]);
        this.subPage = urlParts.length > 1 ? this.formatBreadcrumb(urlParts[1]) : '';
      }
      else {
        this.currentModule = 'Lead Generation';
        this.subPage = '';
      }
    });
  }

  private formatBreadcrumb(str: string): string {
    return str
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  toggleMenu(event: any) {
    event.preventDefault();
    this.collapsed = !this.collapsed;
    this.menuButtonClicked.emit();
  }

  windowScroll() {
    if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
      document.getElementById('page-topbar')?.classList.add('topbar-shadow')
    } else {
      document.getElementById('page-topbar')?.classList.remove('topbar-shadow')
    }
  }

  logout() {
    this.firestoreService.signOut();
    this.authService.logout();
  }

}
